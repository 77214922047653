import React, { useEffect, useState } from 'react'
import "./Header.css"
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { NavLink, useNavigate } from 'react-router-dom';
import LogoImg from '../../Images/home/nagri-logo.svg'
import { getIsLogin, getToken } from '../../Helper/Token';
import axios from 'axios';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { BsTicketPerforated } from 'react-icons/bs'
import UserIcon from '../../Images/home/user.png'
import MenuElement from '../../Images/home/menu-element.svg'
import { getPatchUserDetails, getUserDetails } from '../../Api';
import { FiInstagram, FiMail, FiPhone } from 'react-icons/fi';

const style = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  width: '50%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: '1.5rem',
};

function Header() {
  const navigate = useNavigate()

  // Toggle Menu
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Get Token
  const token = getToken()
  const isLogin = getIsLogin()

  // Get User Details
  const [userDetails, setUserDetails] = useState({})
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        axios.get(`${getUserDetails}/${res.data.user._id}`)
          .then((res) => {
            setUserDetails(res.data.result)
          })
      })
  }
  useEffect(() => {
    fetchUserDetails()
  }, [])

  // Logout 
  const handleLogOut = (e) => {
    e.preventDefault();
    navigate("/")
    setMenuOpen(false)
    localStorage.clear()
    sessionStorage.clear()
  }

  // Sticky Header
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <div className={`Header`}>
      <div className={`top-header ${isSticky ? 'sticky' : ''}`}>
        <div className="header-top-area-shape"></div>
        <div className='container'>
          <div className='top-content'>
            <div className='social-media' >
              <ul>
                <li><a href="mailto:info@nagri.co" ><FiMail style={{color: "var(--primary)"}} />info@nagri.co</a></li>
                <li><a href="tel:+917874834835" ><FiPhone style={{color: "var(--primary)"}} />+91 78748 34835</a></li>
                <li><a href="https://www.instagram.com/nagri.na.norta/" target='_blank'><FiInstagram style={{color: "var(--primary)"}} />/nagri.na.norta</a></li>
              </ul>
            </div>
            <div className='top-right'>
              {
                !isLogin ?
                  <><NavLink to='/login'>Login</NavLink> / <NavLink to='/signup'>Sign Up</NavLink></>
                  :
                  <NavLink to='/profile'><img src={UserIcon} alt="" /> Hello, {userDetails?.name}</NavLink>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={`main-header ${isSticky ? 'sticky' : ''}`}>
        <div className="container">
          <div className='header-main'>
            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"250"} /></NavLink>
            </div>
            <div className='header-menu'>
              <div className='nav-menu'>
                <ul>
                  <li> <NavLink to="/about" >About</NavLink> </li>
                  <li> <NavLink to="/gallery" >Gallery</NavLink> </li>
                  <li> <NavLink to="/support" >Support</NavLink> </li>
                  <li className='button'>
                    <NavLink className="common-btn" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='header-main mobile'>
            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
            </div>

            <div className='header-menu'>
              <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-toggler' type="button" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={menuOpen ? "das-header mobile-nav-menu show" : "das-header mobile-nav-menu"} id="nav-menu">
        <div className="mobile-menu">
          <ul>
            <li className="contact profile">
              <div className='profile-gothrow'>
                <div className='imge_profile'>
                  <img src={UserIcon} alt="" />
                </div>
                {
                  !isLogin ?
                    <div className='profile_text_login_sign center'>
                      <div className='main-heading m-0'>
                        <NavLink to='/login' className="" data-hover="Login">Login</NavLink> / <NavLink className="" to='/signup' data-hover="Sign Up">Sign Up</NavLink>
                      </div>
                    </div> :
                    <NavLink to="/profile" className='profile_text_login_sign' onClick={() => setMenuOpen(false)}>
                      <h1>Hi, {userDetails?.name}</h1>
                      <p>My Profile / <NavLink onClick={(e) => handleLogOut(e)}>Logout</NavLink></p>
                    </NavLink>
                }
              </div>
            </li>
            <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/">Home</NavLink></li>
            <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/about">About</NavLink></li>
            <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/gallery">Gallery</NavLink></li>
            <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/support">Support</NavLink></li>
          </ul>
          <NavLink className="buyNow common-btn mt-4 justify-content-center" to="/buy-now" onClick={() => setMenuOpen(false)} ><BsTicketPerforated /><span>Book Passes</span></NavLink>
          <div className='copyright'>
            <img src={MenuElement} alt="nagri element" />
            <h4>&copy; {currentYear} Nagri</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header