import axios from "axios";
import { useEffect, useState } from "react";
import { getAdminDetails } from "../Api";
import { RxCross1, RxDashboard } from 'react-icons/rx'
import { HiOutlineTicket } from 'react-icons/hi'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineTags } from 'react-icons/ai'
import { MdEvent, MdOutlineLogout } from 'react-icons/md'
import { MdOutlineSupportAgent } from 'react-icons/md'
import { PiMaskHappyLight, PiStudentDuotone } from 'react-icons/pi'
import { PiMicrosoftTeamsLogoLight } from 'react-icons/pi'
import { BsTicketPerforated } from 'react-icons/bs'
import { BsCreditCard2Front } from 'react-icons/bs'
import { TbPlayerTrackPrev } from "react-icons/tb";
import { IoCheckmarkDone } from "react-icons/io5";

export const getToken = () => {
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageToken = sessionStorage.getItem('token');

    const token = sessionStorageToken || localStorageToken || '';
    return axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const getAdminToken = () => {
    const localStorageToken = localStorage.getItem('adminToken');
    const sessionStorageToken = sessionStorage.getItem('adminToken');

    const token = sessionStorageToken || localStorageToken || '';
    return axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const getIsLogin = () => {
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageToken = sessionStorage.getItem('token');

    const isLogin = Boolean(sessionStorageToken) || Boolean(localStorageToken);
    return isLogin;
};

export const getIsAdminLogin = () => {
    const localStorageToken = localStorage.getItem('adminToken');
    const sessionStorageToken = sessionStorage.getItem('adminToken');

    const isLogin = Boolean(sessionStorageToken) || Boolean(localStorageToken);
    return isLogin;
};

export const updateQuotationFormatColor = (color) => {
    document.documentElement.style.setProperty("--quotation-secondary-color", color);
};

export const getUserType = () => {
    const adminLocalStorageToken = localStorage.getItem('adminToken');
    const userLocalStorageToken = localStorage.getItem('userToken');
    const adminSessionStorageToken = sessionStorage.getItem('adminToken');
    const userSessionStorageToken = sessionStorage.getItem('userToken');

    if (adminLocalStorageToken || adminSessionStorageToken) {
        return 'ADMIN';
    } else if (userLocalStorageToken || userSessionStorageToken) {
        return 'USER';
    } else {
        return 'UNKNOWN';
    }
};


export const getAdminRoutes = [
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        icons: <RxDashboard />,
        id: "dashboard",
        key: "DASHBOARD"
    },
    {
        path: "/admin/tickets",
        name: "Tickets",
        icons: <BsTicketPerforated />,
        id: "tickets",
        key: "TICKETS"
    },
    {
        path: "/admin/phases",
        name: "Phases",
        icons: <PiMaskHappyLight />,
        id: "phases",
        key: "PHASES"
    },
    {
        path: "/admin/sold-tickets",
        name: "Sold Tickets",
        icons: <HiOutlineTicket />,
        id: "soldtickets",
        key: "SOLDTICKETS"
    },
    {
        path: "/admin/onlineorders",
        name: "Online Orders",
        icons: <AiOutlineTags />,
        id: "onlineorders",
        key: "ONLINEORDERS",
        hasChild: true,
        child: [
            {
                path: "/admin/orders",
                name: "Processed",
                icons: <IoCheckmarkDone />,
                id: "orders",
                key: "ORDERS"
            },
            {
                path: "/admin/orders/unprocessed",
                name: "Unprocessed",
                icons: <RxCross1 />,
                id: "orders",
                key: "ORDERS"
            }
        ]
    },
    {
        path: "/admin/offlineorders",
        name: "Offline Orders",
        icons: <AiOutlineTags />,
        id: "offlineorders",
        key: "OFFLINEORDERS",
        hasChild: true,
        child: [
            {
                path: "/admin/pre-event-orders",
                name: "Pre Event Orders",
                icons: <TbPlayerTrackPrev />,
                id: "pre-event-tickets",
                key: "PREEVENTORDERS"
            },
            {
                path: "/admin/on-going-event-orders",
                name: "On Going Event Orders",
                icons: <MdEvent />,
                id: "on-going-event-orders",
                key: "ONGOINGEVENTORDERS"
            }
        ]
    },
    // {
    //     path: "/admin/create-cash-tickets",
    //     name: "create cash tickets",
    //     icons: <AiOutlineTags />,
    //     id: "create-cash-tickets",
    //     key: "ORDERS"
    // },
    {
        path: "/admin/users",
        name: "Users",
        icons: <FiUsers />,
        id: "users",
        key: "USERS"
    },
    // {
    //     path: "/admin/pre-event-orders",
    //     name: "Pre Event Orders",
    //     icons: <TbPlayerTrackPrev />,
    //     id: "pre-event-tickets",
    //     key: "PREEVENTORDERS"
    // },
    // {
    //     path: "/admin/on-going-event-orders",
    //     name: "On Going Event Orders",
    //     icons: <MdEvent />,
    //     id: "on-going-event-orders",
    //     key: "ONGOINGEVENTORDERS"
    // },
    // {
    //     path: "/admin/students-orders",
    //     name: "Student Orders",
    //     icons: <PiStudentDuotone />,
    //     id: "students-orders",
    //     key: "STUDENTORDERS"
    // },
    {
        path: "/admin/team-members",
        name: "Team Members",
        icons: <PiMicrosoftTeamsLogoLight />,
        id: "teammembers",
        key: "TEAMMEMBERS"
    },
    {
        path: "/admin/support",
        name: "Support",
        icons: <MdOutlineSupportAgent />,
        id: "support",
        key: "SUPPORT"
    },
    {
        path: "/admin/cms",
        name: "CMS",
        icons: <BsCreditCard2Front />,
        id: "cms",
        key: "CMS"
    },
]


export const calculatePriceBreakdown = (finalPrice, taxPercentage) => {
    const finalPriceNum = parseFloat(finalPrice);
    const gstPercentageNum = parseFloat(taxPercentage);

    if (isNaN(finalPriceNum) || isNaN(gstPercentageNum)) {
        return "Invalid input. Please provide numeric values for finalPrice and gstPercentage.";
    }

    const basePrice = finalPriceNum / (1 + gstPercentageNum / 100);
    const taxPrice = finalPriceNum - basePrice;
    const grandTotal = finalPriceNum;

    return {
        basePrice: basePrice.toFixed(2),
        taxPercentage: gstPercentageNum,
        taxPrice: taxPrice.toFixed(2),
        grandTotal: grandTotal.toFixed(2),
    };
}