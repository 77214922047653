import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";

function FAQs() {
    const faqs = [
        {
            title: "Is booking passes for the Navratri Garba event free?",
            description: "No, booking passes for the Navratri Garba event requires payment. The ticket prices vary depending on the event and location. You can view all available pricing options during the booking process."
        },
        {
            title: "How secure is my data when booking Garba passes online?",
            description: "We take the security and privacy of your data very seriously. Our platform follows industry-standard security protocols to ensure that your personal information and payment details are safe and secure."
        },
        {
            title: "Can I customize the pass with my name or details?",
            description: "Yes, during the booking process, you can enter your name and personal details that will appear on the Garba pass. Make sure to double-check the information before completing your booking."
        },
        {
            title: "Is it easy to book passes if I have limited technical skills?",
            description: "Absolutely! Our pass booking system is designed to be user-friendly and simple. You can easily navigate through the steps without requiring any technical expertise."
        },
        {
            title: "Can I save and access my booked passes for future reference?",
            description: "Yes, once you've booked your passes, you can download or email them to yourself for future use. Additionally, you can always access your booking history through your account on our website."
        },
        {
            title: "Does the booking system support multiple payment methods and currencies?",
            description: "Yes, our platform supports various payment methods such as credit/debit cards, UPI, and online wallets. However, the system currently supports only INR (Indian Rupees) for transactions."
        },
        {
            title: "Can I include additional terms or requests with my booking?",
            description: "Yes, if you have any special requests or requirements, there is a section during the booking process where you can mention them. Our team will do their best to accommodate your needs."
        },
        {
            title: "What if I encounter issues while booking passes for the Navratri Garba event?",
            description: "If you face any difficulties or have questions during the booking process, our support team is ready to assist you. You can contact us via our support portal or email, and we'll help resolve any issues."
        }
    ]

    const [expandedItems, setExpandedItems] = useState(Array(faqs.length).fill(false));

    const toggleAccordion = (index) => {
        setExpandedItems((prevExpandedItems) =>
            prevExpandedItems.map((item, i) => (i === index ? !item : false))
        );
    };

    return (
        <section className='section faqs-section p-100'>
            <div className='container'>
                <div class="main-heading what_is_mandli">
                    <h2 class="and-fonts">Frequently Asked Questions</h2>
                </div>
                <div className="faqs-block">
                    <div className="faqs-custom-section">
                        <div className="accordion-faqs">
                            {faqs.map((faq, index) => (
                                <div className="accordion-item" key={index}>
                                    <button
                                        id={`accordion-button-${index}`}
                                        className={`${expandedItems[index] ? 'expanded' : ''}`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <h2 className="leading-normal accordion-title">{faq.title}</h2>
                                        <span className="icon" aria-hidden="true">
                                            <IoChevronDownOutline />
                                        </span>
                                    </button>
                                    <div className={`accordion-content ${expandedItems[index] ? 'expanded' : ''}`}>
                                        <p>{faq.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQs