import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { getAuthOtp, getUserLogin, getVerifyauthOtp } from '../../Api';
import { Crypt } from 'hybrid-crypto-js';
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import NagriLogo from '../../Images/home/nagri-logo.svg';
import { getIsLogin } from '../../Helper/Token';
import OTPInput from 'react-otp-input';
import { isValidEmail, isValidPhoneNumber } from '../../Helper/validation';


const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Login({ closeProductDeleteModel, ticketData }) {
    var crypt = new Crypt();
    const navigate = useNavigate();

    useEffect(() => {
        if (getIsLogin()) {
            navigate('/profile')
        }
    }, []);

    // VAriables
    const [showPassword, setShowPassword] = useState(false);
    const [stayLoggedIn, setStayLoggedIn] = useState("");
    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });
    const [loginType, setLoginType] = useState("1")
    const [isOtpSend, setIsOtpSend] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [emailErrorShow, setEmailErrorShow] = useState(false)
    const [isVerified, setIsVerified] = useState()
    const [showVarified, setShowVarified] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Timer
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    // Login Type
    const handleLoginType = (e) => {
        setIsOtpSend(false)
        setOTP("")
        setLoginType(e.target.value)
    }

    // Get Enterd Login Data
    const handleChange = (e) => {
        const enteredValue = e.target.value;

        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(enteredValue)) {
            setLoginData({
                ...loginData,
                email: enteredValue,
                phone: ""
            });
        } else if (/^[0-9]{10}$/.test(enteredValue)) {
            setLoginData({
                ...loginData,
                phone: enteredValue,
                email: ""
            });
        } else {
            setLoginData({
                ...loginData,
                password: enteredValue
            })
        }
    };

    // Get Phone No
    const [phoneNo, setPhoneNo] = useState('')
    const handleChangePhone = (e) => {
        const { name, value } = e.target;
        setPhoneNo({
            ...phoneNo,
            [name]: value
        })
    }

    useEffect(() => {
        if (ticketData.phone) {
            setLoginData({
                ...loginData,
                phone: ticketData.phone,
            });

            setPhoneNo({
                ...phoneNo,
                phone: ticketData.phone
            })
        }
    }, [ticketData]);

    // Send Otp
    const [phoneError, setPhoneError] = useState('')
    const [phoneErrorShow, setPhoneErrorShow] = useState(false)
    const handleSendOtp = (e, data) => {
        setPhoneErrorShow(true)
        if (phoneError === "") {
            if (!isVerified) {
                axios.post(getAuthOtp, phoneNo)
                    .then((res) => {
                        toast.success(res.data.message, toastStyle);
                        setTimer(20);
                        setIsOtpSend(true)
                    })
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const phoneValidation = isValidPhoneNumber(phoneNo.phone);
            if (phoneNo.phone === "") {
                setPhoneError('The Phone no. field is mandatory.')
            } else if (!phoneValidation) {
                setPhoneError('please enter 10 digit phone number.')
            } else {
                setPhoneError('')
            }
        }, 200)
    }, [phoneNo])

    // Check stay Logged in
    const handleChecked = (e, data) => {
        if (e.target.checked === true) {
            setStayLoggedIn(true)
        } else {
            setStayLoggedIn(false)
        }
    }

    // Get Otp
    const [OTP, setOTP] = useState("");
    const handleOTPChange = (otp) => {
        setOTP(otp);

        if (otp.length === 4) {
            const formData = new FormData()
            formData.append('phone', phoneNo.phone)
            formData.append('code', otp)
            axios.post(getVerifyauthOtp, formData)
                .then((res) => {
                    setIsLoading(true)
                    setShowVarified(true)
                    setIsVerified(true)
                    const loginInfo = new FormData();
                    loginInfo.append('phone', phoneNo.phone);
                    loginInfo.append('code', otp);

                    axios.post(getUserLogin, loginInfo)
                        .then((res) => {
                            if (stayLoggedIn) {
                                localStorage.setItem("token", res.data.token);
                            } else {
                                sessionStorage.setItem("token", res.data.token);
                            }
                            toast.success(res.data.message, toastStyle);
                            closeProductDeleteModel()
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message, toastStyle);
                        });
                }).catch((err) => {
                    setIsLoading(false)
                    setShowVarified(true)
                    setIsVerified(false)
                })
        } else {
            setIsVerified("")
        }
    };

    // Check Error
    useEffect(() => {
        const { email } = loginData;
        setEmailError(null);
        setPhoneError(null);

        if (email && loginType === '2') {
            if (isValidEmail(email)) {
                setEmailError('');
            } else if (isValidPhoneNumber(email)) {
                setEmailError('');
            } else {
                setEmailError('Invalid email or phone no.');
            }
        }
    }, [loginData]);


    const handleLogin = (e) => {
        e.preventDefault();

        let encryptedPassword = crypt.encrypt(process.env.REACT_APP_PUBLIC_KEY, loginData.password);

        const loginInfo = new FormData();
        if (loginData.email) {
            loginInfo.append('email', loginData.email);
        }
        if (loginData.phone) {
            loginInfo.append('phone', loginData.phone);
        }
        loginInfo.append('password', encryptedPassword);

        axios.post(getUserLogin, loginInfo)
            .then((res) => {
                localStorage.clear();
                sessionStorage.clear();
                if (stayLoggedIn) {
                    localStorage.setItem("token", res.data.token);
                } else {
                    sessionStorage.setItem("token", res.data.token);
                }
                toast.success(res.data.message, toastStyle);
                navigate('/profile')
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            });
    };

    // Show Hide PAssword
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='checkoutLogin'>
            <div className='login-block'>
                <div className='logo'>
                    <div id="nav">
                        <img src={NagriLogo} alt="" />
                    </div>
                </div>
                <div className='at-form'>
                    <div className='login-form'>
                        <div className="loginType">
                            <Form.Check
                                key="OTP"
                                name="LoginType"
                                type="radio"
                                id='OTP'
                                label="Login via OTP"
                                value="1"
                                checked={loginType === "1"}
                                onChange={(e) => handleLoginType(e)}
                                disabled={isVerified}
                            />
                            <Form.Check
                                key="Password"
                                name="LoginType"
                                type="radio"
                                id='Password'
                                label="Login via Password"
                                value="2"
                                checked={loginType === "2"}
                                onChange={(e) => handleLoginType(e)}
                                disabled={isVerified}
                            />
                        </div>
                        <h3>Login into your account</h3>
                        <Form>
                            {
                                loginType === "2" ?
                                    <>
                                        <Form.Group className="form-control" controlId="formBasicEmail">
                                            <Form.Control type="email" inputmode="none" placeholder="Enter Email or Phone no" name="email" onChange={(e) => handleChange(e)} />
                                            {emailErrorShow && <p className='error-msg'>{emailError}</p>}
                                        </Form.Group>

                                        <Form.Group className="form-control password" controlId="formBasicPassword">
                                            <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" inputmode="none" name="password" onChange={(e) => handleChange(e)} />
                                            <Button variant="outline-primary" onClick={toggleShowPassword}>{!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</Button>
                                        </Form.Group>
                                    </> : loginType === "1" ?
                                        <>
                                            <Form.Group className="form-control otpField" controlId="formBasicEmail">
                                                <div>
                                                    <Form.Control type="number" inputmode="none" placeholder="Enter Phone no" name="phone" value={phoneNo.phone} onChange={(e) => handleChangePhone(e)} />
                                                    {phoneErrorShow && <p className='error-msg'>{phoneError}</p>}
                                                </div>
                                                <NavLink className="common-btn" onClick={(e) => handleSendOtp(e)}>Get Otp</NavLink>
                                            </Form.Group>
                                            {
                                                isOtpSend &&
                                                <Form.Group className="form-control otp-inputs" controlId="formBasicPassword">
                                                    <Form.Label>Enter Otp</Form.Label>
                                                    <div className='varifyOTP'>
                                                        <OTPInput
                                                            className="otp-field"
                                                            value={OTP}
                                                            inputmode="none"
                                                            onChange={(e) => handleOTPChange(e)}
                                                            numInputs={4}
                                                            renderInput={(props, index) => (
                                                                <input
                                                                    {...props}
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    inputMode="numeric"
                                                                    disabled={isVerified}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            showVarified && OTP.length === 4 && <>
                                                                {isVerified ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                                                                        <path id="checkmark" d="M25 50 L40 65 L75 30" fill="transparent" stroke="#730000" stroke-width="6" stroke-dasharray="100" stroke-dashoffset="100">
                                                                            <animate attributeName="stroke-dashoffset" begin="0s" dur="1s" to="0" fill="freeze" />
                                                                        </path>
                                                                    </svg> :
                                                                    !isVerified ?
                                                                        <svg width="30" height="30" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7 7.9375C7.30469 7.9375 7.5625 8.19531 7.5625 8.5C7.5625 8.82812 7.30469 9.0625 7 9.0625C6.67188 9.0625 6.4375 8.82812 6.4375 8.5C6.4375 8.19531 6.67188 7.9375 7 7.9375ZM7 7C6.78906 7 6.625 6.83594 6.625 6.625V3.25C6.625 3.0625 6.78906 2.875 7 2.875C7.1875 2.875 7.375 3.0625 7.375 3.25V6.625C7.375 6.83594 7.1875 7 7 7ZM12.8125 8.82812C13.0469 9.22656 13.0469 9.69531 12.8125 10.0938C12.5781 10.5156 12.1562 10.75 11.6641 10.75H2.3125C1.82031 10.75 1.39844 10.5156 1.16406 10.0938C0.929688 9.69531 0.929688 9.22656 1.16406 8.82812L5.85156 0.90625C6.08594 0.507812 6.50781 0.25 7 0.25C7.46875 0.273438 7.89062 0.507812 8.125 0.90625L12.8125 8.82812ZM12.1562 9.71875C12.2734 9.55469 12.25 9.36719 12.1562 9.20312L7.46875 1.28125C7.375 1.11719 7.1875 1.02344 7 1C6.78906 1 6.60156 1.11719 6.50781 1.28125L1.82031 9.20312C1.72656 9.36719 1.70312 9.55469 1.82031 9.71875C1.91406 9.90625 2.10156 10 2.3125 10H11.6641C11.875 10 12.0625 9.90625 12.1562 9.71875Z" fill="#730000" />
                                                                        </svg>
                                                                        : ""}
                                                            </>
                                                        }
                                                    </div>
                                                    <p className='resend-btn'>
                                                        {
                                                            !isVerified &&
                                                            <>
                                                                {timer > 0
                                                                    ? `Resend code in ${timer} seconds`
                                                                    : <><span onClick={(e) => handleSendOtp(e, "otpLess")}>Resend</span> OTP</>}
                                                            </>
                                                        }
                                                    </p>
                                                </Form.Group>
                                            }
                                        </> : ""
                            }

                            <Form.Group className="checkbox" controlId="formBasicCheckbox">
                                <div className='check'>
                                    <Form.Check type="checkbox" onChange={(e) => handleChecked(e)} name="stayLoggedIn" label="Keep me logged in" />
                                </div>
                                <NavLink to="/forgotpassword" >Forgot Password</NavLink>
                            </Form.Group>

                            <Button variant="contained" type="submit" onClick={(e) => handleLogin(e)} className='primaryBtn common-btn' disabled={isLoading}>
                                Login {isLoading && <div className="loginLoader"></div>}
                            </Button>
                            <p className='signup-line'>Don't have account? <NavLink to='/signup' >Sign Up</NavLink></p>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login