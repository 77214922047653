import React, { useEffect } from 'react'
import './PrivacyPolicy.css'
import { NavLink } from 'react-router-dom'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </section>

            <div className='PrivacyPolicy'>
                <section className='section PrivacyPolicy' id='privacy'>
                    <div className='container'>
                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <p>What is Lorem Ipsum?</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. </p>
                                </div>
                                <div className='box-content'>
                                    <h1>What is Lorem Ipsum?</h1>
                                    <ul>
                                        <li><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p></li>
                                        <li>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</li>
                                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</li>
                                        <li>The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</li>
                                        <li>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>What is Lorem Ipsum?</h1>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. </p>
                                    <ul className='points'>
                                        <li>Subject to your compliance with the terms; Common Events Planning Team grants you a limited non-exclusive, non-sub licensable, revocable, and non-transferable through a user identification (user id) reference provided by the website strictly in accordance with the terms of this Agreement. (i) Access and use services in the connection with your use of the Services; and (ii) Access and use any content information and related events that may be made available through the Services, expressly granted herein are reserved by Common Events Planning Team and its licensors.</li>
                                    </ul>
                                    <p>II.	PRIVACY POLICY </p>
                                    <ul className='points'>
                                        <li><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p></li>
                                        <li>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PrivacyPolicy