import React, { useEffect, useRef, useState } from 'react';
import './ContactUs.css';
import "../../Components/Header/Header.css"
import { NavLink, useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Support from '../../Components/Support/Support';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FeedBack from '../../Components/FeedBack/FeedBack';
import bgcoils from '../../Images/bgimg.png'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SiteFooter from '../../Components/Footer/SiteFooter';
import { AiOutlineLink } from 'react-icons/ai';
import FAQs from '../Home/FAQs';

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [showContactSection, setShowContactSection] = useState(false);
  const contactSectionRef = useRef(null);

  // Function to scroll to the contact section
  const scrollToContactSection = () => {
    setShowContactSection(true); // Set the state to true to show the section
    setTimeout(() => {
      if (contactSectionRef.current) {
        contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Small delay to ensure section is rendered before scrolling
  };

  return (
    <>

      <section className='Privacy-Policys buyNow'>
        <div className='inner-banner'>
          <div className="container">
            <h1>Support</h1>
          </div>
        </div>
      </section>


      <div className='Support-page'>
        <div className="content">
          <div className='container'>
            <FAQs />

            {!showContactSection && (<section className='need_further_assistance'>
              <h2 className="and-fonts text-center">Need Further Assistance?</h2>
              <div className='main-heading pt-2 new-btn-style text-center pt-4'>
                <NavLink className="common-btn" onClick={scrollToContactSection}><span>Raise a ticket</span></NavLink>
              </div>
            </section>)}

            {showContactSection &&
              <div className='custom-wrapper-quote'>
                <div className='userdashboardcnt'>
                  <div id="box1" className="box show">
                    <div className='Support-title mb-4'>
                      <h1>How can we help?</h1>
                    </div>
                    <div className='cst-radiobtns-bd'>
                      <Support />
                    </div>
                  </div>
                </div>

                <div className='inner-row-bdy'>
                  <div className='box-details two'>
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <p className='label'>Address</p>
                        <a href="https://maps.app.goo.gl/smGwyVnXGmPwozN28">
                          <span className="elementor-icon-list-text">Nagri Na Norta, Karnavati University, Uvarsad-Adalaj Road, Uvarsad, Gandhinagar, Gujarat 382422<AiOutlineLink /></span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <p className='label'>Email Address</p>
                        <a href="mailto:info@nagri.co">
                          <span className="elementor-icon-list-text isEmail">info@nagri.co</span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <p className='label'>Phone Number</p>
                        <a href="tel:+917874834835">
                          <span className="elementor-icon-list-text isEmail">+91 78748 34835</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>}
          </div>
        </div>

      </div>
    </>
  );
};

export default ContactUs;
